@import "../../../../colors";

.Courses {
  position: relative;
  padding: 35px 20px;

  .courses-content {
    position: relative;

    .courses-loading-container {
      position: relative;
      margin-top: 100px;
    }

    .course-outer-container {

      a {
        text-decoration: none;
      }

      .course-container {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 4px 4px 4px 0 #24374614;
        margin-bottom: 16px;
        display: flex;
        flex-direction: row;

        .course-image-container {
          height: 150px;
          width: 150px;
          overflow: hidden;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;

          img {
            height: 100%;
            margin-left: -100%;
            transform: translateX(50%);
          }
        }

        .course-details-container {
          flex: 1;
          overflow: hidden;
          padding: 10px;

          .course-title {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            font-weight: bold;
          }

          .course-description {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
