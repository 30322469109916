@import "../../../../../../../colors";

.QuizQuestion {
  margin: 50px 0;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  .question-number {
    font-size: 14px;
  }

  .question {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .multiple-choice-answer {
    display: block;
    color: $textColor;
    margin-bottom: 12px;
    margin-left: 10px;
  }

  .error-message {
    color: $primaryColor;
    margin: 0;
  }

  button {
    margin-top: 8px;
    width: 120px;
  }
}
