@import "../../../../colors";

.Profile {
  position: relative;

  .profile-header {
    background-color: $secondaryColorDark;
    padding: 20px 0;

    h1 {
      color: #fff;
    }
  }

  .profile-content {
    margin-top: 35px;

    .profile-loading-container {
      position: relative;
      margin-top: 100px;
    }

    .account-content-container {
      padding-bottom: 50px;

      .save-changes-button {
        width: 100%;
      }

      .secondary-buttons-container {
        margin-top: 50px;

        .sign-out-button {
          margin-right: 7px;
        }

        .update-password-button {
          width: 178px;
        }
      }
    }
  }
}
