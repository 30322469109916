@import "../../../../../../colors";

.VideoContent {
    
  .video-container {
    width: 100%;
    padding-top: 56.25%;
    position: relative;

    .video-inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $backgroundColor;
    }
  }
}
