@import "../../../colors";

.Sidebar {
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.05);

  a {
    text-decoration: none;

    .nav-item {
      color: #fff;
      text-decoration: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      padding: 14px;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;

      i {
        margin-right: 15px;
      }

      &:hover {
        opacity: 0.85;
      }

      &.active {
        color: $secondaryColorDark;
        background-color: #fff;
        -webkit-box-shadow: inset 5px 0px 0px 0px $primaryColor; 
        box-shadow: inset 5px 0px 0px 0px $primaryColor;
        border-color: $secondaryColorDark;

        &:hover {
          opacity: 1;
        }
      }

      &.sign-out {
        opacity: 0.7;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}
