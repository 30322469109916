.MainContent {
  position: relative;
  padding-top: 56%;
  overflow: hidden;

  .main-content-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
  }
}
