@import "../../../../../../colors";

.QuizContent {
  position: relative;
  height: 100%;

  .completed-message-container {
    height: 100%;
    overflow-y: auto;
    padding: 20px;

    .completed-message {
      text-align: center;

      .completed-message-content {

        @media (max-width: 375px) {
          h1 {
            font-size: 24px;
          }

          p {
            font-size: 14px;
          }
        }

        @media (max-width: 300px) {
          h1 {
            font-size: 18px;
          }

          p {
            font-size: 12px;
          }
        }
      }
    }
  }

  .start-quiz-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    padding: 20px;

    .start-quiz {
      margin-left: auto;
      margin-right: auto;

      h1 {
        font-size: 20px;
        max-width: 340px;
      }

      p {
        max-width: 340px;
      }

      @media (max-width: 400px) {

        h1 {
          font-size: 16px;
        }
  
        p {
          font-size: 12px;
        }
      }
    }
  }

  .current-question-container {
    height: 100%;
    overflow-y: auto;
    padding: 20px;
  }
}
