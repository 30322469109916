@import "../../../../../colors";

.Navigation {
  position: fixed;
  height: calc(100vh - 64px);
  width: 25%;

  &.admin {
    width: calc(25% - 50px);
  }

  .navigation-inner-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .navigation-header-container {
      background-color: #fff;
      padding: 15px;
      border-bottom: 1px solid $borderDark;
  
      h3 {
        margin: 0;
        font-size: 15px;
      }
    }
  
    .navigation-sections-container {
      overflow-y: auto;

      .section-title-container {
        background-color: #fff;
        border-width: 0;
        border-radius: 0;
        border-bottom: 1px solid $borderLight;
        width: 100%;
        text-align: left;
        padding: 15px;
        display: flex;
        flex-direction: row;
        text-transform: none;

        .section-title-details {
          flex: 1;

          .section-title {
            font-weight: bold;
            margin: 0;
            margin-bottom: 5px;
            font-size: 14px;
          }

          .section-completion {
            margin: 0;
            font-weight: 400;
            font-size: 12px;
          }
        }

        .fa-chevron-down {
          color: $secondaryColorDark;
          transition: all 0.2s ease;
          margin-left: 7px;

          &.open {
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
          }
        }
      }

      .module-container {
        padding: 15px;
        font-size: 14px;
        border-width: 0;
        border-radius: 0;
        width: 100%;
        text-align: left;
        text-transform: none;
        background-color: $backgroundColor;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
          background-color: $borderLight;
          opacity: 1;
        }

        &.active {
          background-color: $borderLight;
        }

        .module-complete-container {
          margin-right: 12px;

          i {
            font-size: 20px;
            color: $borderDark;

            &.fa-check-circle-o {
              color: $successColor;
            }
          }
        }

        .module-text-container {

          .module-title {
            margin: 0;
            margin-bottom: 5px;
            font-weight: 600;
          }
  
          .module-type {
            margin: 0;
            font-weight: 400;
            text-transform: capitalize;
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 980px) {
    position: relative;
    height: 100%;
    width: 100%;

    &.admin {
      width: 100%;
    }

    .navigation-inner-container {

      .navigation-header-container {
        border-top: 1px solid $borderDark;
      }
    }
  }
}
