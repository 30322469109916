@import "../../../../colors";

.Course {
  position: relative;

  .course-outer-container {
    display: flex;
    flex-direction: row;

    .course-content-container {
      width: 75%;
      border-right: 1px solid $borderLight;
    }

    .course-navigation-container {
      width: 25%;
      position: relative;
    }

    @media (max-width: 980px) {
      flex-direction: column;

      .course-content-container {
        width: 100%;
        border-right-width: 0;
      }

      .course-navigation-container {
        width: 100%;
      }
    }
  }
}
