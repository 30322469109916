.Account {
  position: relative;

  .account-content-container {
    padding: 20px;

    .header-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .update-password-button {
        width: 178px;
      }
    }

    .save-changes-button {
      width: 100%;
    }
  }
}
