@import "../../../../../../../colors";

.QuizSummary {
  text-align: left;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;

  .summary-container {
    margin-bottom: 40px;

    .fa-check-circle-o {
      color: $successColor;
    }

    .fa-times-circle-o {
      color: $primaryColor;
    }

    .correct-answer {
      margin-left: 15px;
      margin-top: 0;
      font-size: 14px;
      margin: -10px 0 0 20px;
    }
  }
}
