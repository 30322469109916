@import "../../../colors";

.SignIn {

  .form-container {
    max-width: 600px;
    padding: 70px 20px;
    margin: auto;

    .auth-well {

      .logo-container {
        text-align: center;

        .logo {
          height: 40px;
          margin: 20px 0;
        }
      }

      h1 {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        margin: 20px 0 20px;
      }

      button {
        width: 100%;
        margin-top: 20px;
      }
    }

    a {
      font-size: 12px;
      color: $linkColor;
      text-decoration: underline;
    }

    .forgot-password-link-container {
      text-align: center;
      padding: 12px 0;
    }

    .or-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      .or-divider {
        border-bottom: 1px solid $borderDark;
        flex: 1;
      }

      p {
        margin: 10px 15px;
        font-size: 14px;
        color: #777777;
      }
    }

    .create-account-button-container {
      button {
        margin-top: 15px;
      }
    }

    .terms-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      margin: 15px 0;

      .terms-container-inner {
        display: flex;
        flex-direction: row;
        align-items: center;

        .terms-divider {
          margin: 0 10px;
          font-size: 12px;
          color: #777777;
        }
      }
    }

    .copyright-container {
      text-align: center;

      p {
        color: #777777;
        font-size: 12px;
        margin: 25px 0 5px;
      }
    }
  }
}
