@import "../../../../colors";

.Team {
  position: relative;

  .team-content-container {
    padding: 20px;

    .header-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .add-team-member-button {
        width: 176px;
      }
    }

    .table-container {
      width: 100%;
      overflow-x: scroll;

      table {
        border-collapse: collapse;
        width: 100%;
        background-color: #fff;
        border: 1px solid $borderLight;
        color: $textColor;

        th {
          text-align: left;
          border-bottom: 1px solid $borderDark;
        }

        td {
          border-bottom: 1px solid $borderLight;

          &.button-container {
            text-align: right;
            width: 100px;

            button {
              font-size: 12px;
              padding: 5px 10px;
            }
          }
        }

        th, td {
          padding: 10px 12px;
        }

        .capitalize {
          text-transform: capitalize;
        }
      }
    }
  }
}
