@import "../../../../../colors";

.AdditionalContent {
  position: relative;
  background-color: #fff;

  .tabs-container {
    display: flex;
    flex-direction: row;
    border-top: 1px solid $borderLight;
    border-bottom: 1px solid $borderLight;

    .tab-container {
      flex: 1;
      cursor: pointer;
      text-align: center;
      padding: 12px;
      color: #6a6f73;
      font-weight: bold;

      &:hover {
        opacity: 0.8;
      }

      &.active {
        -webkit-box-shadow: inset 0px -2px 0px 0px $secondaryColorDark; 
        box-shadow: inset 0px -2px 0px 0px $secondaryColorDark;
        color: $secondaryColorDark;
      }
    }
  }

  .notes-container {
    padding: 20px;
    padding-bottom: 100px;
    white-space: pre-wrap;
    color: $textColor;
  }

  .links-container {
    padding: 20px;
    padding-bottom: 100px;
    color: $textColor;
  }

  @media (max-width: 980px) {
    .notes-container,
    .links-container {
      padding-bottom: 30px;
    }
  }
}
