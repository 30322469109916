.Associate {
  position: relative;

  .content-container {
    margin-top: 64px;

    .associate-max-width {
      padding: 0 20px;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
